import React, { useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {  useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	addfeedbackDispatch,
	getCustomerIdDispatch,
} from '../reducers/HomeReducer';

// import './ReviewForm.css'; // Optional: Add your styles

const ReviewForm = () => {
	const { id, name } = useParams(); // Access both id and name parameters
	const dispatch = useDispatch();
	const { cust } = useSelector((state) => state.home);
	const initialValues = {
		rating: 0,
		contact: '',
		review: '',
	};
	console.log('id', id);
	console.log('name', name);
	useEffect(() => {
		dispatch(getCustomerIdDispatch(id));
	}, []);
	const validationSchema = Yup.object({
		contact: Yup.string()
			.email('Invalid email format')
			.matches(/^\d{10}$/, 'Enter a valid phone number')
			.optional(),
		review: Yup.string()
			.max(500, 'Review must be less than 500 characters')
			.required('Review is required'),
	});
	// console.log('data : '+cust?.result.url);
	const handleSubmit = (values, { resetForm }) => {
		// console.log(values);
		// alert('Review Submitted!');
		let form = {
			name: values.rating,
			phone: values.contact,
			email: values.email,
			cust_id: id,
			api_key: cust?.result?.apikey,
			desc: values?.review,
		};
		dispatch(addfeedbackDispatch(form));
		resetForm();
	};

	const navigate = useNavigate();
	return (
		<div className='review-form' style={{ marginTop: 100 }}>
			<h2>Leave a Review</h2>
			<Formik
				initialValues={initialValues}
				// validationSchema={validationSchema}
				onSubmit={handleSubmit}>
				{({ setFieldValue, values }) => (
					<Form>
						<div className='rating'>
							<label>How was your experience?</label>
							<div>
								{[1, 2, 3, 4, 5].map((star) => (
									<span
										key={star}
										className={`star ${values.rating >= star ? 'filled' : ''}`}
										onClick={() => {
											setFieldValue('rating', star);
											console.log('first', star);
											if (star > 3) {
												// window.location.href = cust?.result?.url;
												navigate('/review');
											}
										}}>
										★
									</span>
								))}
							</div>
						</div>

						<div className='form-group'>
							<label>Your Contact:</label>
							<Field
								type='text'
								name='contact'
								placeholder='Enter your phone number'
							/>
							<ErrorMessage name='contact' component='div' className='error' />
						</div>
						<div className='form-group'>
							<label>Your Email :</label>
							<Field type='email' name='email' placeholder='Enter your Email' />
							<ErrorMessage name='email' component='div' className='error' />
						</div>
						<div className='form-group'>
							<label>Write a Review:</label>
							<Field
								as='textarea'
								name='review'
								placeholder='Share your experience...'
								rows='4'
								style={{ border: '1px solid lightgrey', width: '100%' }}
							/>
							<ErrorMessage name='review' component='div' className='error' />
						</div>

						<button type='submit' className='submit-btn'>
							Submit
						</button>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default ReviewForm;
