import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Rating,
  Container,
} from "@mui/material";
import { useSelector } from "react-redux";

const ReviewGen = () => {
    const { cust } = useSelector((state) => state.home);
  const [review, setReview] = useState("");
  const navigate = useNavigate();

  // Predefined restaurant reviews
  const reviews = [
    "The food at Taste Haven is absolutely divine! Their signature dishes burst with flavor and the service is impeccable.",
    "Dining at Spice Villa was a delightful experience. The aromatic spices in their cuisine and warm ambiance made it unforgettable.",
    "Flavorsome Bistro offers a perfect blend of taste and presentation. Their desserts are to die for!",
    "The Gourmet Nook never disappoints! Fresh ingredients and creative recipes make every meal a treat.",
    "Savor Palace has the best comfort food in town. Their portions are generous and the taste is consistently amazing.",
  ];

  // Function to set random review
  const setRandomReview = () => {
    const randomIndex = Math.floor(Math.random() * reviews.length);
    setReview(reviews[randomIndex]);
  };

  useEffect(() => {
    setRandomReview();
  }, []);

  // Function to copy review and redirect
  const handleCopyReview = () => {
    navigator.clipboard.writeText(review).then(() => {
     window.location.href = cust?.result?.url;
    });
  };
// console.log('data : '+cust?.result.agency_name)
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Box
        sx={{
          minHeight: "100vh",
          background: "linear-gradient(135deg, #6B48FF 0%, #00DDEB 100%)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 4,
          width: { xs: "100%", md: "30%" },
        }}
      >
        {/* Header */}
        <Typography
          variant="h4"
          sx={{
            color: "#fff",
            textAlign: "center",
            fontWeight: "bold",
            marginBottom: 4,
          }}
        >
          {cust?.result?.agency_name}
        </Typography>

        {/* Review Card */}
        <Container maxWidth="sm">
          <Card
            sx={{
              background: "rgba(255, 255, 255, 0.1)",
              backdropFilter: "blur(10px)",
              borderRadius: 3,
              padding: 2,
              textAlign: "center",
            }}
          >
            <CardContent>
              <Rating value={5} readOnly sx={{ marginBottom: 2 }} />
              <Typography
                variant="body1"
                sx={{ color: "#fff", marginBottom: 2 }}
              >
                {review}
              </Typography>
              <Button
                variant="contained"
                onClick={handleCopyReview}
                sx={{
                  backgroundColor: "#fff",
                  color: "#6B48FF",
                  fontWeight: "bold",
                  borderRadius: 20,
                  padding: "10px 20px",
                }}
              >
                COPY REVIEW
              </Button>
            </CardContent>
          </Card>
        </Container>

        {/* Footer */}
        <Box sx={{ display: "flex", alignItems: "center", marginTop: 4 }}>
          <Typography sx={{ color: "#fff", marginRight: 1 }}>
            Powered By
          </Typography>
          <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
            ZOODONIX
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ReviewGen;
